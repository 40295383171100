/* eslint-disable max-lines */
import { ReactNode } from 'react';
import { IPhasePhilosophy } from '../../libs/models/cbo/EngagementModels';

export const confirmationModalText = {
  Confirm: 'Yes',
  cancel: 'No',
  subTitle: `Are you sure do you want to delete?`,
};

export enum EngagementPhases {
  INITIATE = 'Initiate',
  ASSESS = 'Assess',
  DESIGN = 'Design',
  PLATFORM_BUILD = 'Build',
  CONSTRUCT = 'Construct',
  TEST = 'Test',
  OPERATE = 'Operate',
  BLANK = '',
  INITIATE_ASSESS = 'Initiate/Assess',
  TRANSITION = 'Transition',
  STABILIZE_SUSTAIN = 'Stabilize & Sustain',
  OPTIMIZE_INNOVATE = 'Optimize & Innovate',
  TRANSFORM = 'Transform',
  DEFINE = 'DEFINE',
  SHAPE = 'SHAPE',
  BUILD = 'BUILD',
  SCALE = 'SCALE',
}

export const journey = {
  operateToTransformation: 'Operate to Transform',
};

export const planTabCapability = {
  pMaas: 'Program Setup and Operation - PMaaS',
};
export const jiraSprintActivities = {
  migrate: 'migrate',
  modernize: 'modernize',
};

export const tabsTrafficMapper = (value: string): string => {
  switch (value) {
    case EngagementPhases.INITIATE:
      return 'initiate';
    case EngagementPhases.ASSESS:
      return 'assess';
    case EngagementPhases.DESIGN:
      return 'design';
    case EngagementPhases.PLATFORM_BUILD:
      return 'build';
    case EngagementPhases.CONSTRUCT:
      return 'construct';
    case EngagementPhases.TEST:
      return 'test';
    case EngagementPhases.OPERATE:
      return 'operate';
    default:
      return value;
  }
};

export enum ButtonLabels {
  SAVE = 'Save',
  CANCEL = 'Cancel',
  NEXT = 'Next',
  BACK = 'Back',
  SUBMIT = 'Submit',
  ADD = 'Add',
  DELETE = 'Delete',
  EDIT = 'Edit',
  CLOSE = 'Close',
  VIEW = 'View',
  UPDATE = 'Update',
  RESET = 'Reset',
  CONFIRM = 'Confirm',
  OK = 'Ok',
}

export enum FileUploadLabels {
  SUCCESS = 'File uploaded successfully',
  FAILED = 'Failed to upload file:',
  SIZEERROR = 'File is larger than maximum allowed size. (100KB)',
  INVALIDTYPE = 'Invalid file type. Please choose a valid file type',
}

export const noDataAltText = 'No Data Available';

export const regexPattern = {
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  phone: /^[0-9]{10}$/,
  number: /^[0-9]*$/,
  alphaNumeric: /^[a-zA-Z0-9]*$/,
  url: /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g,
  password:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&^$*.[\]{}()\-\"!@#%&\/\\,><':;|_~`+=]{8,}$/,
};

export const adUserFields = 'id, displayName,mail,jobTitle';
export const adUserFilter = `userType eq 'member' and startswith(displayName,'searchText') or userType eq 'member' and startswith(givenName,'searchText') or userType eq 'member' and startswith(surname,'searchText') or userType eq 'member' and startswith(mail,'searchText') or userType eq 'member' and startswith(userPrincipalName,'searchText')`;
export const colorRangeSelector = [
  '#ff9999', // Dark Red
  '#99ff99', // Dark Green
  '#9999ff', // Dark Blue
  '#ffff99', // Dark Yellow
  '#ff99ff', // Dark Magenta
  '#99ffff', // Dark Cyan
  '#ffb380', // Dark Orange
  '#dcdcff', // Dark Lavender
  '#7dbf7d', // Dark Green
];

export const marketplaceTabKeysConstant = {
  keyAssets: 'Key Assets',
  mandatorySmartStarts: 'Mandatory Smart Starts',
  gtmArtifacts: 'GTM Artifacts',
  industrySpecific: 'Industry Specific',
  dep: 'DEP',
};

export const environments = {
  dev: 'dev',
  qa: 'qa',
  prod: 'prod',
};
